@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.preset-thumbnails__cols {
  display: flex;
  flex-wrap: wrap;
}
.preset-thumbnails__col {
  width: 100px;
  margin-left: 15px;
  margin-top: 20px;

  @include media('xs-only') {
    margin-right: 10px;
  }

  @include media('sm-and-up') {
    margin-left: 15px;

    &:nth-child(4n + 1) {
      margin-left: 0;
    }
  }
}
.preset-thumbnails__thumb {
  position: relative;
  display: block;
  width: 100px;
  height: 100px;
  background-color: #d8d8d8;

  &:hover {
    box-shadow: inset 0px 0px 0px 3px lighten($brand-red, 20%);

    img {
      outline: 3px solid lighten($brand-red, 20%);
      outline-offset: -3px;
    }
  }

  .is-selected & {
    box-shadow: inset 0px 0px 0px 3px $brand-red;

    img {
      outline: 3px solid $brand-red;
      outline-offset: -3px;
    }
  }

  img {
    width: 100%;
  }
}
.preset-thumbnails__loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) scale(0.85);
  opacity: 0;
  transition: opacity 0.2s ease;

  .is-selected.is-loading & {
    opacity: 1;
  }
}
.preset-thumbnails__title {
  margin-top: 10px;
  text-align: center;

  > p {
    font-size: 0.7em;
    font-weight: bold;
    margin: 0;
    color: $grey-600;

    // truncation
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;

    .is-selected & {
      color: black;
    }
  }
}
