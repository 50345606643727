@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.feedback {
  color: black;
  width: 85vw;
  max-width: 400px;
}
.feedback__item {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin-bottom: 8px;
  padding-bottom: 4px;

  .v-icon {
    width: 25px;
    flex-grow: 0;
  }

  .v-alert {
    width: 100%;
    margin-bottom: -5px;
  }
}
.feedback__store {
  flex-grow: 1;
  white-space: nowrap;
  margin-left: 6px;
  margin-right: 10px;
  font-size: 14px;
}
