@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.post-info {
  @include media('sm-and-up') {
    width: 100%;
  }

  @include media('xs-only') {
    margin-right: 15px;
  }
}
.post-info__cols {
  display: flex;
  align-items: center;
}
.post-info__col {
  &:first-child {
    font-size: 14px;
    flex-grow: 0;
    margin-right: 15px;
  }
  &:last-child {
    flex-grow: 1;
  }
  &--thumb {
    flex-basis: 80px;
    margin-right: 24px !important;

    .is-video & {
      display: none;
    }

    @include media('sm-and-up') {
      display: none;
    }

    + .post-info__col {
      position: relative;
    }
  }
}
.has-red-asterisk {
  position: relative;
  display: inline-block;

  &:before {
    position: absolute;
    left: -8px;
    top: -3px;
    content: '* ';
    color: crimson;
    font-size: 0.9em;
  }
}
.post-info__thumb {
  width: 100%;
  display: block;
}
.post-info__info {
  margin-left: 7px;
  margin-top: -5px;
  width: 16px;
  height: 16px;

  .v-icon {
    font-size: 8px;
  }
}
