@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.preset-categories {
  margin-top: -8px;
}
.chip {
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
}
::v-deep {
  .v-slide-group__prev {
    //padding-left: 0;
    //justify-content: flex-start;
    margin-left: -10px;
  }
  .v-slide-group__next,
  .v-slide-group__prev {
    flex: 0 1 auto;
    min-width: 30px;

    .preset-categories &--disabled .v-icon {
      color: rgba(black, 0.2) !important;
    }

    .v-icon {
      font-size: 15px;
      font-weight: bold;
    }
  }
}

.is-active {
  background-color: $brand-red;
  color: white;
}
