@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.create-navigation {
  width: 564px;
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}
.create-navigation__cancel {
  width: 108px;
  margin-right: auto;
}
.create-navigation__back {
  width: 108px;
}
.create-navigation__next {
  width: 130px;
  margin-left: 20px;
}
