@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.asset-title {
  margin-top: 36px;

  .v-text-field {
    padding-top: 6px;
  }
}
.asset-title__hint {
  font-size: 0.75em;

  b {
    text-transform: uppercase;
  }

  & span.is-required {
    position: relative;

    b {
      color: $brand-red;
    }

    &:before {
      content: '* ';
      position: absolute;
      top: -5px;
      left: -8px;
      color: crimson;
      font-size: 1.2em;
      letter-spacing: -0.5px;
    }
  }
}
