@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.stage {
  position: relative;

  @include media('xs-only') {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.stage__container {
  position: relative;
  overflow: hidden;

  @include media('sm-and-up') {
    border: 1px solid black;
    border-radius: 6px;
  }

  &.is-portrait {
    @include aspect-ratio(0.84, 1);
  }
  &.is-square {
    @include aspect-ratio(1, 1);
  }
}

.stage__clear-btn {
  position: absolute;

  @include media('xs-only') {
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  @include media('sm-and-up') {
    right: 0;
    top: -46px;
  }
}

.stage__aspect-toggle {
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;

  @include media('xs-only') {
    margin-bottom: 20px;
  }

  @include media('sm-and-up') {
    position: absolute;
    top: 12px;
  }

  .v-btn {
    text-transform: none;
    color: $grey-600;

    &--active {
      color: white;
      background-color: $brand-red;
    }
  }
}
