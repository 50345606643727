@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

h1 {
  margin-top: 89px;
  font-size: 1.75em;
  margin-bottom: 16px;
}
.create__cols {
  @include media('sm-and-up') {
    display: flex;
  }
}
.create__col {
  &:first-child {
    @include media('sm-and-up') {
      flex-basis: 564px;
      flex-shrink: 0;
      flex-grow: 0;
      margin-right: 20px;
    }
  }
  &:last-child {
    @include media('xs-only') {
      background-color: white;
      padding-top: 20px;
      margin-left: -15px;
      margin-right: -15px;
    }
    @include media('sm-and-up') {
      flex-basis: 446px;
      flex-grow: 1;
      flex-shrink: 1;
      overflow: hidden;
    }
  }
}
.create__col-gutter {
  padding-left: 20px;

  @include media('xs-only') {
    padding-left: 15px;
  }
}
.v-stepper__items {
  width: 100%;
}

.mob-top-bg {
  @include media('xs-only') {
    background-color: #f7f7f7;
  }
}

.library {
  background-color: lightblue;
  height: 50vh;
}
