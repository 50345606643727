@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.import {
  margin-top: 20px;
}
.import__take-photo-wrap {
  display: flex;
  align-items: stretch;
  @include aspect-ratio(343, 172);
}
.import__take-photo-btn {
  width: 100%;
  height: auto !important;

  ::v-deep .v-btn__content {
    flex-wrap: wrap;
  }
}
.import__camera-icon {
  width: 100%;
}
.import__upload-wrap {
  display: flex;
  align-items: stretch;
  @include aspect-ratio(49, 12);
}
.import__upload-btn {
  width: 100%;
  height: auto !important;
}
.import__library {
  text-align: center;
}
